// -----------------------------------------
//  Базовые цвета
// -----------------------------------------

$msl_main_text_color:					#5a5a5a;		// основной цвет текста
$msl_placeholder_text_color:			#a1a1a1;		// текст-плейсхолдер
$msl_border_1:							#a1a1a1;		// граница 1
$msl_border_2:							#5a5a5a;		// граница 2
$msl_base_green:						#009788;		// базовый зеленый цвет
$msl_darken_green:						#005a51;		// затемненный зеленый
$msl_base_green_conversion_color:		#005a51;		// цвет конверсии
$msl_inactive_color:					#808080;		// неактивный блок
$msl_turn_off_terminal_color:			#dc6d27;		// выключение терминала
$msl_error:								#df5050;		// ошибка/удаление
$msl_red:								#df5050;		// наш красный цвет
$msl_non_active_block:					#e5e5e5;		// неактивный блок

$msl_base_yellow: #E3B90B;
$msl_darken_yellow: #ba9707;
$msl_light_yellow: #ffffcc;
$color_of_disabled_button: gray;
$color_of_header_text: gray;

$color_text_allowed: #20bb42;
$color_text_not_allowed: red;

$msl_base_border_green: 4px solid $msl_base_green;
$border_radius_green_btn: 8px;

$size_of_header: 54px;									// высота заголовка

// -----------------------------------------
//  Описания слоев (z-index)
// -----------------------------------------

$layer_hamburger: 20;									// уровень меню-гамбургер
$layer_communication_dialog: 19;						// уровень коммуникационного модуля
$layer_kare_dialog: 19;									// уровень диалога для "Каре"
$layer_communication_header_icon: 20;					// уровень кнопок в верхней панели
$layer_sportprognoz_dialog: 85;							// уровень диалога печати для "Спортпрогноз"
$layer_status_header_icon: 60;							// уровень кнопок в верхней панели
$layer_keyboard: 75;									// уровень виртуальной клавиатуры
$layer_calendar: 77;									// уровень компонента выбора даты
$layer_system_info: 80;									// уровень диалога со списком информации
$layer_dialog: 90;										// уровень всплывающих диалогов
$layer_curtain: 100;									// шторка инициализации

// -----------------------------------------
//  Константы ширин различных экранов
// -----------------------------------------

$screen_size_1024: 1024px;								// 4:3, 16:10, 5:3, 16:9
$screen_size_1136: 1136px;								// 16:9
$screen_size_1152: 1152px;								// 4:3, 16:10
$screen_size_1280: 1280px;								// 5:4, 4:3, 3:2, 16:10, 5:3, 16:9
$screen_size_1366: 1366px;								// 16:9
$screen_size_1440: 1440px;								// 1440x900 (8:5)
$screen_size_1536: 1536px;								// 1536x864	5.35%
$screen_size_1600: 1600px;								// 1600x900 (16:9)
$screen_size_1680: 1680px;								// 1680x1050 (8:5)

// -----------------------------------------
//  Шрифты
// -----------------------------------------
$roboto_light: RobotoLight, Geneva, Arial, Helvetica, sans-serif;
$roboto_regular: RobotoRegular, Geneva, Arial, Helvetica, sans-serif;
$roboto_medium: RobotoMedium, Geneva, Arial, Helvetica, sans-serif;
$roboto_bold: RobotoBold, Geneva, Arial, Helvetica, sans-serif;
$roboto_mono: 'Roboto Mono', monospace;
$fira_sans: 'Fira Sans Extra Condensed', Geneva, Arial, Helvetica, sans-serif;
