@import 'variables.scss';

&_white {
	border-radius: 8px;
	border: 2px solid $msl_main_text_color;
	background: #FFFFFF;
	color: $msl_main_text_color;
	text-transform: uppercase;
	font-size: inherit;
	font-family: inherit;
}
