@import 'variables';
@import 'mixins';

&_rect-light-green {
	background: #FFFFFF;
	box-shadow: inset 0 0 20px 0 rgba(0, 151, 136, 0.3);
	border: 2px solid $msl_base_green;

	color: $msl_main_text_color;
	font-size: 24px;

	min-height: 4vw;
	height: auto;
	text-transform: none;
}
