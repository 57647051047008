@import 'variables';

&_toggle {
	color: $msl_main_text_color;
	text-transform: initial;
	font-family: $roboto_regular;
	font-size: 24px;
	background: radial-gradient(white, white 0%, #d8f4f4);
	box-shadow: inset 0 0 0 2px $msl_base_green;
}
