@import 'variables';

.button {
	width: 100%;

	font-family: $roboto_medium;
	font-size: 36px;

	text-align: center;
	text-transform: uppercase;

	cursor: pointer;
	border-radius: 8px;

	&_theme {
		@import './_theme/button_theme_dark-green';
		@import './_theme/button_theme_gold';
		@import './_theme/button_theme_green';
		@import './_theme/button_theme_light-green';
		@import './_theme/button_theme_rect-light-green';
		@import './_theme/button_theme_toggle';
		@import './_theme/button_theme_yellow';
		@import './_theme/button_theme_white';
	}

	&[disabled] {
		color: $msl_placeholder_text_color;
		background: $msl_inactive_color;
		border: none;
	}
}
