@import 'variables.scss';

&_yellow {
	border-radius: 8px;
	border: 2px solid $msl_base_yellow;
	background: $msl_base_yellow;
	color: #FFFFFF;
	text-transform: uppercase;
	font-size: inherit;
	font-family: inherit;
	font-weight: bold;
}
