@import "variables";

@mixin radial_bg($color) {
	background: #ffffff; /* Old browsers */
	background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, $color 100%); /* FF3.6-15 */
	background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%, $color 100%); /* Chrome10-25,Safari5.1-6 */
	background: radial-gradient(ellipse at center, #ffffff 0%, $color 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin msl_placeholder($font-size: 18px) {
	font-size: $font-size;
	font-family: $roboto_regular;
	color: $msl_inactive_color;
	display: block;
	position: relative;
	top: -4px;
}

// белый крестик
@mixin cross_mark($color: #FFFFFF) {
	&:before, &:after {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) rotate(45deg);

		content: '';
		background: $color;
	}
	&:before {
		width: 20px;
		height: 2px;
	}
	&:after {
		width: 2px;
		height: 20px;
	}
}

// прелоадер
@mixin circle_arc($d: 175px, $w: 12px) {
	width: $d;
	height: $d;
	border-top: $w solid $msl_base_green;
	border-left: $w solid #b7e4e1;
	border-bottom: $w solid #b7e4e1;
	border-right: $w solid #b7e4e1;
	border-radius: 50%;
	animation: rotate 2s linear infinite;
	margin: 0 auto;
}
