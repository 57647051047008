@import 'variables';
@import 'mixins';

&_gold {
	border-radius: 8px;
	border: 2px solid $msl_base_yellow;
	@include radial_bg($color: #FAEFC4);
	text-transform: uppercase;
	font-size: inherit;
	font-family: inherit;
	font-weight: bold;
	color: $msl_main_text_color;
}
